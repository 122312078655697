import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import PropTypes from "prop-types"
import { useInterval } from "usehooks-ts"
import detectEthereumProvider from "@metamask/detect-provider"
import * as ethers from "ethers"
import Cookies from "js-cookie"
import { IWalletContext } from "./interfaces/walletContext.interface"
import { IWalletProps } from "./interfaces/walletProps.interface"

export const WalletContext = createContext<IWalletContext>({
  isMetamaskEnabled: false,
  signed: false,
  settings: undefined,
  login: () => {},
  logout: () => {},
})

interface IProviderProps {
  children: React.ReactNode
}

export const Provider = ({ children }: IProviderProps) => {
  const savedCallback: any = useRef()

  const [wallet, setWallet] = useState<IWalletProps>({})
  const {
    signed,
    account,
    ethereum,
    isMetamaskEnabled,
    initialized,
    provider,
    settings,
    contract,
    whitelisted,
  } = wallet

  useEffect(() => {
    ;(async () => {
      try {
        const ethereum: any = await detectEthereumProvider()
        if (!ethereum) {
          throw "NO_METAMASK"
        }

        await new Promise((res) => setTimeout(res, 2000))
        await setWallet({
          signed: false,
          ethereum,
          isMetamaskEnabled: !!ethereum,
        })
      } catch (e) {
        await new Promise((res) => setTimeout(res, 2000))
        await setWallet({
          signed: false,
          ethereum: null,
          initialized: true,
          isMetamaskEnabled: false,
        })
      }
    })()
  }, [])

  useEffect(() => {
    savedCallback.current = setInterval(() => {
      updateSettings()
    }, 5000)
    return () => clearInterval(savedCallback.current)
  }, [wallet])

  const getSettings = async (contract: any) => {
    if (!contract) return
    try {
      const [
        unixOpenWL,
        unixOpenAll,
        unixCloseAll,
        tokenSupply,
        amountBuyed,
        cgangBuyed,
        cgangPriceInBnb,
        optionOnePrice,
        optionTwoPrice,
        optionThreePrice,
        optionFourPrice,
      ]: ethers.BigNumber[] = await contract.settings()

      return {
        unixOpenWL: unixOpenWL.toNumber(),
        unixOpenAll: unixOpenAll.toNumber(),
        unixCloseAll: unixCloseAll.toNumber(),
        tokenSupply: tokenSupply.toNumber(),

        // optionOneAmount: optionOneAmount.toNumber(),
        optionOnePrice: optionOnePrice.toString(),
        // optionTwoAmount: optionTwoAmount.toNumber(),
        optionTwoPrice: optionTwoPrice.toString(),
        // optionThreeAmount: optionThreeAmount.toNumber(),
        optionThreePrice: optionThreePrice.toString(),

        optionFourPrice: optionFourPrice.toString(),
        amountBuyed: amountBuyed.toString(),
        cgangBuyed: cgangBuyed.toNumber(),
        cgangPriceInBnb: cgangPriceInBnb.toNumber(),
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateSettings = useCallback(async () => {
    if (contract) {
      const settings = await getSettings(contract)
      setWallet({
        ...wallet,
        settings,
      })
    }
  }, [wallet, contract])

  const init = useCallback(async () => {
    if (wallet.signed) {
      return
    }

    const provider = new ethers.providers.Web3Provider(ethereum)
    const network = await provider.getNetwork()

    if (network.chainId !== 56) {
      setWallet({
        ...wallet,
        initialized: true,
      })

      throw `You must be connect on BSC to buy. Verify your network and try again`
    }

    const selectedAddress = ethereum.selectedAddress
    const session = Cookies.get(`cgangsters_auth_${selectedAddress}`)

    if (session) {
      const provider = new ethers.providers.Web3Provider(ethereum)
      const signer = provider.getSigner()
      const contract = new ethers.Contract(
        process.env.REACT_APP_TOKEN_CONTRACT!,
        [
          "constructor()",
          "function buyOptionFour() payable",
          "function buyOptionOne() payable",
          "function buyOptionThree() payable",
          "function buyOptionTwo() payable",
          "function setCgangPriceInBnb(uint256)",
          "function setStartUnixTime(uint256)",
          "function setWhitelist(address[])",
          "function withdrawBNB()",
          "function withdrawToken(address,uint256)",
          "function buyers(address) view returns (uint256, uint256)",
          "function owner() view returns (address)",
          "function settings() view returns (uint256, uint256, uint256, uint256, uint256, uint256, uint256, uint256, uint256, uint256, uint256)",
          "function whitelists(address) view returns (bool)",
        ],
        signer
      )

      const settings = await getSettings(contract)

      await contract.deployed()
      const whitelisted = await contract.whitelists(selectedAddress)

      setWallet({
        ...wallet,
        signed: true,
        account: selectedAddress,
        initialized: true,
        whitelisted,
        settings,
        contract,
        provider,
      })
      return
    }

    setWallet({
      ...wallet,
      initialized: true,
    })
  }, [ethereum, wallet])

  useEffect(() => {
    if (!ethereum) {
      return
    }

    setTimeout(init, 100)
  }, [ethereum, init])

  const login = useCallback(async () => {
    const accounts = await ethereum.request({ method: "eth_requestAccounts" })
    const selectedAccount = accounts[0]

    // Cookies.set(`cgangsters_auth_${selectedAccount}`, "signed")

    await init()
    Cookies.set(`cgangsters_auth_${selectedAccount}`, "signed")
  }, [ethereum, wallet])

  const logout = useCallback(() => {
    Object.keys(Cookies.get()).forEach((cookie) => {
      Cookies.remove(cookie)
    })

    setWallet({
      ...wallet,
      signed: false,
      account: undefined,
    })
  }, [wallet])

  const onAccountChange = useCallback(async () => {
    logout()
  }, [logout])

  useEffect(() => {
    if (!ethereum) {
      return
    }
    ethereum.on("accountsChanged", onAccountChange)
    ethereum.on("chainChanged", (chainId: any) => {
      // Handle the new chain.
      // Correctly handling chain changes can be complicated.
      // We recommend reloading the page unless you have good reason not to.
      window.location.reload()
    })
    return () => ethereum.removeAllListeners()
  }, [ethereum, onAccountChange])

  useEffect(() => {
    if (!account) {
      return
    }
  }, [account, ethereum, onAccountChange])

  return !initialized ? (
    <></>
  ) : (
    <WalletContext.Provider
      value={{
        signed,
        ethereum,
        isMetamaskEnabled,
        initialized,
        account,
        contract,
        provider,
        settings,
        whitelisted,
        login,
        logout,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider
