import React from "react"
import "./App.css"
import Provider from "@Provider"
import { ThemeProvider } from "@mui/material/styles"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import { BrowserRouter as Router } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import { ToastContainer } from 'react-toastify'
import {Helmet} from "react-helmet"
import theme from "./Constants/theme"
import Main from "./Containers/Main"

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
          <Provider>
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>CryptoGangsters Whitelist - Presale</title>

								<meta property="og:site_name" content="CryptoGangsters" />
								<meta property="og:title" content="CryptoGangsters" />
								<meta property="og:description" content="P2E Game on BSC Network" />
								<meta property="og:type" content="website" />
								<meta property="og:url" content="https://cryptogangsters.me/" />

								{/* Facebook */}
								<meta property="og:image" content="https://cryptogangsters.me/static/media/mafia.185d2ce0.png" />
								<meta property="og:image:type" content="image/jpeg" />
								<meta property="og:image:width" content="400" />
								<meta property="og:image:height" content="300" />
								<meta property="og:image:alt" content="CryptoGangsters" />

								{/* Twitter */}
								<meta name="twitter:card" content="summary_large_image" />
								<meta name="twitter:site" content="@CGANGstersNFT" />
								
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="cache-control" content="no-cache, no-store, must-revalidate" />
                <meta name="theme-color" content="#000000" />
            </Helmet>
            <Router>
              <Main />
              <ToastContainer />
            </Router>
            </>
          </Provider>
      </StyledThemeProvider>
    </ThemeProvider>
  )
}

export default App
